import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import CallToAction from "../components/CallToAction";
import Box from "../components/elements/Box";
import Layout from "../components/Layout";
import Section from "../components/Section";
import SEO from "../components/Seo";

const NotFoundPage = ({ data }) => (
  <Layout transparentHeader={false}>
    <SEO title="404: Not found" />
    <Box height={"6rem"} />
    <Section title="404 Not found">
      <Img
        fluid={data.notFound.childImageSharp.fluid}
        css={`
          width: 50%;
          margin: 0 auto;
        `}
      />
    </Section>

    <CallToAction />
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
  {
    notFound: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
